import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { createContext, useCallback, useContext, useState } from 'react';

type DialogComponentProps = {
  onFinish: (success: boolean) => void;
};

type DialogContextType = {
  openDialog: <T extends DialogComponentProps = DialogComponentProps>(
    title: string,
    Component: React.FC<T>,
    props?: Omit<T, 'onFinish'>
  ) => Promise<boolean>;
};

const DialogContext = createContext<DialogContextType | undefined>(undefined);

export const DialogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    title: string;
    Component: React.ComponentType<DialogComponentProps> | null;
    props: Omit<DialogComponentProps, 'onFinish'> | null;
    resolve: ((value: boolean) => void) | null;
  }>({
    open: false,
    title: '',
    Component: null,
    props: null,
    resolve: null
  });

  const openDialog = useCallback(
    (title: string, Component: React.FC<DialogComponentProps>, props: DialogComponentProps) => {
      return new Promise<boolean>((resolve) => {
        setDialogState({
          open: true,
          title,
          Component,
          props,
          resolve
        });
      });
    },
    []
  );

  const closeDialog = useCallback(
    (success: boolean) => {
      if (dialogState.resolve) {
        dialogState.resolve(success);
      }
      setDialogState({ open: false, title: '', Component: null, props: null, resolve: null });
    },
    [dialogState]
  );

  return (
    <DialogContext.Provider value={{ openDialog: openDialog as any }}>
      {children}
      <Dialog open={dialogState.open} onClose={() => closeDialog(false)}>
        <DialogTitle>{dialogState.title}</DialogTitle>
        <DialogContent>
          {dialogState.Component && <dialogState.Component onFinish={closeDialog} {...dialogState.props} />}
        </DialogContent>
      </Dialog>
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};

import { filter, flatten, map, uniqBy } from 'lodash-es';
import { BaseEntity } from '~/lib/model/base';
import { Association } from '~/models/association';
import { CustomerCategory } from '~/models/customerCategory';
import { Industry } from '~/models/industry';
import { EnergyType, Meter, MIRN, NMI } from '~/models/meter';
import { Project, ProjectStatus } from '~/models/project';
import { Relationship } from '~/models/relationship';
import { IUser } from '~/models/user';

export enum CompanyStatus {
  PROSPECT = 'PROSPECT', // create a new company, status starts from PROSPECT
  ENGAGED = 'ENGAGED', // when its one project become PROJECT_APPROVED, and previous status is ESTABLISHMENT
  CUSTOMER = 'CUSTOMER', // When its all projects become CLOSED
  INACTIVE = 'INACTIVE', // When its all projects become REJECTED, and all contracts are expired
  ACTIVE_PROJECT = 'ACTIVE_PROJECT',
  WIN_BACK = 'WIN_BACK'
}

export enum CompanyType {
  COUNCIL_AGENT = 'COUNCIL_AGENT',
  GROUP_COMPANY = 'GROUP_COMPANY'
}

export enum SiteStatus {
  Single = 'Single',
  Multi = 'Multi'
}

export interface ICompany {
  id: string;
  isCouncilAgent: boolean;
  isGroupCompany: boolean;
  tradingName: string;
  legalName: string;
  abn: string;
  acn: string;
  accountManagerId: string;
  currentStatus: CompanyStatus;
  engagementDateReceived: string;
  potentialContractEndDate: string;
  lastCallDate?: string;
  futureCallBackDate?: string;
  parentCompany?: ICompany;
  subsidiaries?: ICompany[];
  nmis?: NMI[];
  mirns?: MIRN[];
}

interface ICreateContactRequest {
  name: string;
  salutation?: string;
  position?: string;
  phone?: string;
  mobile?: string;
  fax?: string;
  email?: string;
  directLine?: string;
  website?: string;
  precedence?: number;
}

interface ICreateContractRequest {
  contractStartDate: string;
  contractEndDate?: string;
  description?: string;
}

export interface ICompanyCreateRequest {
  isCouncilAgent: boolean;
  isGroupCompany: boolean;
  tradingName: string;
  legalName: string;
  abn: string;
  acn?: string;
  serviceTypeIds: string[];
  industryId: string;
  customerCategoryId: string;
  relationshipId?: string;
  referredById?: string;
  accountManagerId?: string;
  currentStatus: CompanyStatus;
  engagementDateReceived?: string;
  potentialContractEndDate?: string;
  lastCallDate?: string;
  futureCallBackDate?: string;
  contacts: ICreateContactRequest[];
  contracts: ICreateContractRequest[];
  streetAddress?: string;
  suburb?: string;
  city?: string;
  state?: string;
  postCode?: string;
  country?: string;
  description?: string;
  parentCompanyId?: string;
}

export interface ServiceType {
  id: string;
  code: string;
  name: string;
  description: string;
}

export interface CompanyContact {
  id: string;
  name: string;
  salutation?: string;
  position?: string;
  phone?: string;
  mobile?: string;
  fax?: string;
  email?: string;
  directLine?: string;
  website?: string;
  precedence?: number;
}

export interface CompanyContract {
  id: string;
  contractStartDate: string;
  contractEndDate?: string;
  description?: string;
}

export class Company extends BaseEntity implements ICompany {
  id!: string;
  parentCompanyId?: string;
  isCouncilAgent!: boolean;
  isGroupCompany!: boolean;
  tradingName!: string;
  legalName!: string;
  abn!: string;
  acn!: string;
  accountManagerId!: string;
  currentStatus!: CompanyStatus;
  engagementDateReceived!: string;
  potentialContractEndDate!: string;
  lastCallDate?: string;
  futureCallBackDate?: string;
  serviceTypes!: ServiceType[];
  nmi?: string[];
  mirn?: string[];
  contacts!: CompanyContact[];
  contracts!: CompanyContract[];
  industry?: Industry;
  customerCategory?: CustomerCategory;
  relationship?: Relationship;
  referredBy?: Association;
  accountManager?: IUser;
  streetAddress?: string;
  suburb?: string;
  city?: string;
  state?: string;
  postCode?: string;
  description?: string;
  nmis?: NMI[];
  mirns?: MIRN[];
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
  parentCompany?: ICompany;
  subsidiaries?: ICompany[];
  projects?: Project[];

  get name(): string {
    return this.tradingName || this.legalName;
  }

  get formattedName(): string {
    let str = this.tradingName;
    if (this.legalName) {
      str += `<br/><i>(Legal Name: ${this.legalName})</i>`;
    }
    return str;
  }

  get meters(): Meter[] {
    return [
      ...(this.nmis || []).map((v) => ({ ...v, energyType: EnergyType.ELECTRICITY } as NMI)),
      ...(this.mirns || []).map((v) => ({ ...v, energyType: EnergyType.GAS } as MIRN))
    ];
  }

  get activeProjects(): Project[] {
    return filter(
      this.projects ? this.projects : uniqBy(flatten(map(this.meters, 'projects')), 'id'),
      (project) =>
        ![
          ProjectStatus.CLOSED,
          ProjectStatus.CANCELLED,
          ProjectStatus.OPPORTUNITY_CANCELLED,
          ProjectStatus.OFFER_REJECTED
        ].includes(project.status)
    );
  }
}

export enum CompanySearchCriteriaSortBy {
  CREATED_DATE = 'CREATED_DATE',
  UPDATED_DATE = 'UPDATED_DATE',
  METER_CONTRACT_END_DATE = 'METER_CONTRACT_END_DATE',
  COMPANY_NAME = 'COMPANY_NAME',
  PARENT_COMPANY = 'PARENT_COMPANY',
  COMPANY_STATUS = 'COMPANY_STATUS',
  ACCOUNT_MANAGER = 'ACCOUNT_MANAGER',
  REFERRED_BY = 'REFERRED_BY'
}

export enum CompanySearchCriteriaOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface CompanySearchCriteria {
  companyName?: string;
  abn?: string;
  acn?: string;
  contactName?: string;
  postCode?: string;
  meterPostCode?: string;
  meterContractEndDateFrom?: string | null;
  meterContractEndDateTo?: string | null;
  potentialContractEndDateFrom?: string | null;
  potentialContractEndDateTo?: string | null;
  accountManagerIds?: string[];
  customerCategoryIds?: string[];
  companyStatus?: CompanyStatus;
  siteStatus?: SiteStatus | null;
  companyIds?: string[];
  projectStatus?: ProjectStatus;
  sortBy?: CompanySearchCriteriaSortBy;
  order?: CompanySearchCriteriaOrder;
}

export interface ICompanyOutlineResponse {
  id: string;
  tradingName?: string;
  legalName?: string;
  abn?: string;
  acn?: string;
  parentCompany?: ICompany;
}

export type IUpdateCompanyRequest = Company;
